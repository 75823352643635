import { dateToJson } from '../utils/DateUtil';
import { GetCall, PostCall, PutCall } from './_serviceHelperApi'
import { GetCall as Get, PostCall as Post, DeleteCall as Delete } from './_serviceHelper2'
import { UrlApi } from '../config/Services'

const serviceUrl = `${UrlApi()}/usuarios`

/**
 * @returns obj devuelto: {errores, data}
 */
export const listarUsuarios = (filters) => {
    var params = {
        id: filters?.id,
        activo: filters?.activo
    }
    return Get(serviceUrl, params)
}

export const autocompUsuario = (texto) => {
    return Get(serviceUrl + '/autocompletar', [{ 'key': 'texto', 'val': texto }]);
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const agregarUsuario = (unUsuario) => {
    return PostCall(serviceUrl, unUsuario)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const modificarUsuario = (unUsuario) => {
    return PutCall(serviceUrl, unUsuario)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const borrarUsuarios = (listaIds) => {
    return PutCall(serviceUrl + '/borrar', listaIds)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const reactivarUsuarios = (listaIds) => {
    return PutCall(serviceUrl + '/reactivar', listaIds)
}

/**
 * @returns lista de transacciones para el cliente seleccionado. Obj devuelto: {errores, data}
 */
export const listarTransacciones = (filters) => {
    var params = [
        { 'key': 'clienteId', 'val': filters?.clienteId },
        { 'key': 'fechaDesde', 'val': dateToJson(filters?.fechaDesde) },
        { 'key': 'fechaHasta', 'val': dateToJson(filters?.fechaHasta) },
        { 'key': 'pageNumber', 'val': filters?.pageNumber },
        { 'key': 'pageSize', 'val': filters?.pageSize }
    ];
    return GetCall(serviceUrl + '/transacciones', params)
}

/**
 * @param {objTransaccion} unaTransaccion {cliente, importe, tipoTransaccion}
 * @returns obj devuelto: {errores, data}
 */
export const agregarTransaccion = (unaTransaccion) => {
    return PostCall(serviceUrl + '/transacciones', unaTransaccion)
}

/********* CONTROL DE SESIONES *********/

export const listarTenantsDisponibles = (email) => Get(serviceUrl+'/tenants', {email})
export const buscarInfoDeTenant = () => Get(serviceUrl+'/tenants/info')
export const listarLogos = (email, listaTenantIds) => Get(serviceUrl+'/logos', {email, t: listaTenantIds})
export const login = (email, pass, tenantId) => Post(serviceUrl+'/sesion', {usuario: {email, pass}, t: tenantId})
export const logout = () => Delete(serviceUrl+'/sesion')

/********* CONSULTAS DE TENANTS *********/
export const listarModulosDeTenant = () => Get(serviceUrl+'/tenants/modulos')