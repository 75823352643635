import React from 'react'
import { DataTable } from '../../../comp/DataTable'
import {
    listarCategoriasProducto,
    agregarCategoriaProducto,
    modificarCategoriaProducto,
    borrarCategoriasProducto,
    reactivarCategoriasProducto
} from '../../../services/servCategoriasProductos'
import { ModalDelete } from '../../../comp/ModalDelete'
import { Acciones } from '../../../utils/Acciones'
import { Tipos } from '../../../utils/Tipos'
import { Detalle } from './contenido'
import { Messages } from '../../../comp/Messages2'

export const CategoriasProductos = () => {

    const tableConfig = {
        title: 'ViewCategoriasProductos',
        headers: ['TXT_NOMBRE'],
        types: [Tipos().DESC],
        access: ['nombre'],
        rowId: 'id'
    }

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])

    const [resServicio, setResServicio] = React.useState()

    const handleDetailOpen = (action, selection) => {
        setResServicio()
        setDetailAction(action)
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
            setIsDelReacOpened(true)
        } else {
            setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
            setIsDetailOpened(true)
        }
    }

    const handleDetailSubmit = async (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        let res
        switch (detailAction) {
            case Acciones().ADD:
                res = await agregarCategoriaProducto(obj)
                if (!res.errores) {
                    obj.id = res.data.id
                    obj.activo = true
                    tableData.unshift(obj)
                    formatDataForTable([obj])
                }
                break
            case Acciones().EDIT:
                res = await modificarCategoriaProducto(obj)
                if (!res.errores) tableSelection[0].nombre = obj.nombre
                break
            case Acciones().DEL:
                res = await borrarCategoriasProducto(tableSelection.map(e => e.id))
                if (!res.errores) tableSelection.forEach(e => { e.activo = false })
                break
            case Acciones().REAC:
                res = await reactivarCategoriasProducto(tableSelection.map(e => e.id))
                if (!res.errores) tableSelection.forEach(e => { e.activo = true })
                break
            default: return
        }
        setResServicio(res)
        if (!res.errores) {
            setTableSelection([])
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
        }
    }

    const formatDataForTable = (datos) => {
        if (datos.find(e => e.id === 1)) 
            datos.find(e => e.id === 1).editable = false
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        listarCategoriasProducto()
            .then(res => {
                formatDataForTable(res.data)
                setTableData(res.data)
                setIsTableLoading(false)
            })
    }, [])

    return <>
        <DataTable title={tableConfig.title}
            headers={tableConfig.headers}
            types={tableConfig.types}
            access={tableConfig.access}
            rowId={tableConfig.rowId}
            data={tableData}
            isLoading={isTableLoading}
            openDetail={handleDetailOpen}
            hasAdd hasEdit hasReac hasDel />
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
        <Messages resServicio={resServicio} accion={detailAction} />
    </>
}