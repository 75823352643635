import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

import { AutocompleteCliente } from '../../../comp/AutocompleteCliente'
import { DataTableProductosElegidos } from '../../../comp/DataTableProductosElegidos'
import { InputImporte } from '../../../comp/Input'
import { InputSelect } from '../../../comp/InputSelect'
import { ModalDetail } from '../../../comp/ModalDetail'
import { ModalSearch } from '../../../comp/ModalSearch'
import { TicketToPrint } from '../Ticket'
import { Traduccion } from '../../../comp/Traduccion'

import { Acciones } from '../../../utils/Acciones'
import { Print } from '../../../utils/Printer'
import { Acceso, NivelAcceso } from '../../../utils/Seguridad'

import { Resumen } from './nuevo/Resumen'

import { LoadingRect } from '../../../comp/LoadingComp';
import { agregarMovimiento, listarDetallesCobros, listarDetallesProductos, listarFormasDePago } from '../../../services/servCajas'
import { Messages } from '../../../comp/Messages2'

const esVenta = (id) => [-2, 1].includes(id)
const calcularVuelto = (vendido, cobrado) => vendido === 0 ? 0
    : cobrado - vendido < 0 ? 0
        : cobrado - vendido

const DetalleFormasDePago = ({data = [], isLoading}) => {
    return <Card>
                <CardHeader title={Traduccion('TXT_FORMAS_DE_PAGO')} />
                <CardContent>
                    <Grid container spacing={2}>
                        { isLoading &&
                            <Grid item xs={12} sm={4} md={12}>
                                <Skeleton variant="rect" animation="wave" height={'3em'}/>
                            </Grid>
                        }
                        { data.map((elem, idx) =>
                            <Grid key={idx} item xs={12} sm={4} md={12}>
                                <InputImporte label={elem.formaDePago.id < 1 ? Traduccion(elem.formaDePago.nombre) : elem.formaDePago.nombre}
                                            value={elem.importe}
                                            editable={false}/>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
}

export const Detalle = ({ventaId, cliente, vendido, cobrado, motivo = {}, fechaHora, isOpen, onSubmit}) => {

    const [isLoadingDetProductos, setIsLoadingDetProductos] = React.useState(true)
    const [isLoadingDetCobros, setIsLoadingDetCobros] = React.useState(true)
    const [detProductos, setDetProductos] = React.useState([])
    const [detCobros, setDetCobros] = React.useState([])
    const [isPrinting, setIsPrinting] = React.useState(null)
    const [resServicio, setResServicio] = React.useState()

    const imprimirComprobante = () => setIsPrinting(ventaId)

    React.useEffect(()=>{
        if (isPrinting !== null) {
            document.getElementById('printTicketModal').click()
            setIsPrinting(null)
        }
    }, [isPrinting])

    React.useEffect(() => {
        if (ventaId) {
            setIsLoadingDetProductos(true)
            setDetProductos([])
            setResServicio()
            if ([-2,1].includes(motivo.id)) { //si es una venta
                setIsLoadingDetProductos(true)
                listarDetallesProductos({id: ventaId})
                    .then(resp => {
                        setDetProductos(resp.data)
                        resp.errores && setResServicio(resp)
                        setIsLoadingDetProductos(false)
                    })
            } else {
                setIsLoadingDetProductos(false)
            }
            setIsLoadingDetCobros(true)
            setDetCobros([])
            listarDetallesCobros({id: ventaId})
                    .then(resp => {
                        setDetCobros(resp.data)
                        resp.errores && setResServicio(resp)
                        setIsLoadingDetCobros(false)
                    })
        }
    }, [ventaId, motivo])

    return <div>
        <ModalDetail isOpen={isOpen} 
                    onSubmit={onSubmit}
                    accion={Acciones().SEE}
                    maxWidth={esVenta(motivo.id) ? "md" : "sm"}
                    masAcciones={[{funcion: imprimirComprobante, texto: 'TXT_IMPRIMIR'}]}>
            <Grid container spacing={2}>
            { cliente &&
                <Grid item xs={12}>
                    <AutocompleteCliente editable={false} seleccion={cliente}/>
                </Grid>
            }
            { (isLoadingDetProductos || detProductos?.length > 0) &&
                <Grid item xs={12}>
                    <DataTableProductosElegidos data={detProductos} isLoading={isLoadingDetProductos}/>
                </Grid>
            }
                <Grid item xs={12} md={6}>
                    <DetalleFormasDePago data={detCobros} isLoading={isLoadingDetCobros}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Resumen totalVendido={vendido}
                            totalCobrado={cobrado}
                            readOnly/>
                </Grid>
            </Grid>
        </ModalDetail>
        <Messages resServicio={resServicio} accion={Acciones().SEE} />
        {isPrinting &&
        <Print id='printTicketModal'>
            <TicketToPrint nroTicket={isPrinting}
                            fechaHora={fechaHora}
                            detallesProductos={detProductos}
                            totalVendido={vendido}
                            totalCobrado={cobrado}
                            vuelto={calcularVuelto(vendido, cobrado)}/>
        </Print>
        }
    </div>
}

export const ModalRegistrarVenta = ({isOpen, cajaOperada, detalleProductos = [], onSubmit}) => {

    const [cliente, setCliente] = React.useState(null)
    const [defaultDetCobros, setDefaultDetCobros] = React.useState([])
    const [isLoadingDefaultDetCobros, setIsLoadingDefaultDetCobros] = React.useState(true)
    const [detCobros, setDetCobros] = React.useState([])
    const [formSubmitted, setFormSubmitted] = React.useState(false)
    const [isTransaccionActiva, setIsTransaccionActiva] = React.useState(false)
    const [isPrinting, setIsPrinting] = React.useState(null)
    const [resServicio, setResServicio] = React.useState()

    const updateFormaPago = (importe, id) => setDetCobros(
        detCobros.map(e => 
            id.includes('_'+e.formaDePago.id)
                ? {...e, importe: importe} 
                : e
        )
    )

    const calcularTotalVendido = () => detalleProductos.map(p => p.precio * p.cantidad).reduce((sum, aux) => sum + aux, 0)
    const calcularTotalCobrado = () => detCobros.map(c => c.importe).reduce((sum, aux) => sum + aux, 0)

    const isClienteRequerido = () => detCobros.find(dc => dc.formaDePago.id === -2 && dc.importe > 0) !== undefined
    const obligatoriosConValor = () => (!isClienteRequerido() || cliente)

    const modalSubmitted = (isSubmited) => {
        //si solo cancelo no hago nada, solo invoco el callback
        if (!isSubmited) return onSubmit(false)
        //si obligatorios no están completos interrumpo
        if (!obligatoriosConValor()) return setFormSubmitted(true)
        setIsTransaccionActiva(true)
        var movimiento = {
            caja: cajaOperada,
            motivoMovimiento: {id: 1}, //venta por caja
            detallesProductos: detalleProductos,
            detallesCobros: detCobros,
            cliente: cliente
        }
        setResServicio()
        agregarMovimiento(movimiento).then(resListar => {
            setIsTransaccionActiva(false)
            resListar.errores && setResServicio(resListar)
            setIsPrinting(resListar.data?.id)
        })
    }

    //estoy atento a si debo imprimir el comprobante
    React.useEffect(()=>{
        if (isPrinting) {
            document.getElementById('print').click()
            setIsPrinting(null)
            setCliente(null)
            setDetCobros(defaultDetCobros)
            setFormSubmitted(false)
            onSubmit(true)
        }
    }, [isPrinting, defaultDetCobros, onSubmit])

    //preparo los datos iniciales
    React.useEffect(() => {
        setIsLoadingDefaultDetCobros(true)
        listarFormasDePago({activo: true}).then(res => {
            let aux = res.data.map(e => ({
                formaDePago: {
                    id: e.id,
                    nombre: e.id < 0 ? Traduccion(e.nombre) : e.nombre
                },
                importe: 0
            }))
            aux.sort((f1,f2) => f1.formaDePago.nombre.localeCompare(f2.formaDePago.nombre))
            setDefaultDetCobros(aux)
            setDetCobros(aux)
            setIsLoadingDefaultDetCobros(false)
        })
    }, [])

    return <div>
        <ModalDetail customTitle={'TXT_REGISTRAR_VENTA'}
            isOpen={isOpen}
            onSubmit={modalSubmitted}
            accion={Acciones().ADD}
            isLoading={isTransaccionActiva}
            maxWidth="sm">
            <Grid container spacing={2}>
                {
                    Acceso('ViewClientes', NivelAcceso().VER) &&
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <AutocompleteCliente id='autocompCliente'
                                    requerido={isClienteRequerido()}
                                    seleccion={cliente}
                                    onSelect={setCliente}
                                    validarSubmit={isClienteRequerido() && !cliente && formSubmitted}
                                    editable={!isTransaccionActiva} />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <InputImporte id='saldo_cliente'
                                    label={Traduccion('TXT_SALDO')}
                                    value={cliente ? cliente.saldo : 0.0}
                                    editable={false} />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <InputImporte id='disponible_cliente'
                                    label={Traduccion('TXT_CREDITO_DISPONIBE')}
                                    value={cliente ? cliente.topeCredito + cliente.saldo : 0.0}
                                    editable={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardHeader title={Traduccion('TXT_FORMAS_DE_PAGO')} />
                        <CardContent>
                            <Grid container spacing={2}>
                                {isLoadingDefaultDetCobros &&
                                    <Grid item xs={12}>
                                        <LoadingRect rows={2} />
                                    </Grid>
                                }
                                {detCobros.map(elem => (
                                    <Grid item xs={12} key={elem.formaDePago.id}>
                                        <InputImporte id={'tipoCobro_'+elem.formaDePago.id}
                                            name={elem.formaDePago.nombre}
                                            label={elem.formaDePago.nombre}
                                            value={elem.importe}
                                            onChange={(val, id) => updateFormaPago(val, id)}
                                            autoFocus={elem.formaDePago.id === 1}
                                            editable={!isTransaccionActiva} />
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Resumen totalVendido={calcularTotalVendido()}
                        totalCobrado={calcularTotalCobrado()}
                        vuelto={calcularVuelto(calcularTotalVendido(), calcularTotalCobrado())}
                        readOnly />
                </Grid>
            </Grid>
        </ModalDetail>
        <Messages resServicio={resServicio} accion={Acciones().ADD} />
        {isPrinting && 
        <Print>
            <TicketToPrint nroTicket={isPrinting}
                            fechaHora ={new Date().toISOString()}
                            detallesProductos={detalleProductos}
                            totalVendido={calcularTotalVendido()}
                            totalCobrado={calcularTotalCobrado()}
                            vuelto={calcularVuelto(calcularTotalVendido(), calcularTotalCobrado())}/>
        </Print>
        }
    </div>
}

//------------------

export const Filtros = ({values, motivos, isOpen, onSubmit}) => {

    const [motivoId, setMotivoId] = React.useState(values.motivoId);
    
    const _handleSubmit = (submit) => {
        if (!submit) {
            setMotivoId(values.motivoId);
        }
        let objRes = {
            caja: values.caja,
            motivo: {id: motivoId === -1 ? null : motivoId}
        };
        onSubmit(submit, objRes);
    };
    
    const opciones = () => {
        return [{label: Traduccion('TXT_SELECCION_TODOS'), value: -1}]
                .concat(motivos.map(m => {
                    return {
                        label: m.id < 1 ? Traduccion(m.nombre) : m.nombre,
                        value: m.id
                    }
                }))
    }

    return <ModalSearch isOpen={isOpen} onSubmit={res => _handleSubmit(res)}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputSelect label={Traduccion('TXT_MOTIVO')}
                             value={motivoId ? motivoId : -1}
                             onChange={id => setMotivoId(id)}
                             options={opciones()}/>
            </Grid>
        </Grid>
    </ModalSearch>;
};