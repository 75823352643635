import React from 'react'
import { Grid, Stack, Tooltip } from '@mui/material'
import { Button,
        Card,
        CardHeader,
        CardActions,
        CardContent } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BadgeIcon from '@mui/icons-material/Badge'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import { Menu,
         MenuItem } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { HistoricoCaja } from './HistoricoCaja'
import { AjusteCaja } from './AjusteCaja'

import { Detalle as ModificacionCaja } from '../contenido'

import { Warn } from '../../../comp/Message'
import { TooltipButton } from '../../../comp/TooltipButton'
import { Traduccion } from '../../../comp/Traduccion'

export const DatosCaja = ({datosCaja, handleVolver}) => {

    const [mostrarAjuste, setMostrarAjuste] = React.useState(false)
    const [mostrarEdicionCaja, setMostrarEdicionCaja] = React.useState(false)
    const [mostrarHistorico, setMostrarHistorico] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleSubmitEdicionCaja = (submit) => !submit && setMostrarEdicionCaja(false)
    
    const mostrarMenu = (val) => {
        if (typeof val !== 'number') return setAnchorEl(val.currentTarget)
        setAnchorEl(null)
        switch (val) {
            case 1: setMostrarAjuste(true)
                break
            case 2: setMostrarEdicionCaja(true)
                break
            case 3: setMostrarHistorico(true)
                break
            default: break
        }
    }

    return <>
        <Card>
            <Menu anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => mostrarMenu(0)}
                anchorOrigin={{vertical: "top",horizontal: "right"}}
                transformOrigin={{vertical: "top",horizontal: "right"}}>
                <MenuItem onClick={() => mostrarMenu(1)}>
                    <ListItemIcon>
                        <AttachMoneyIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        {Traduccion("TXT_AJUSTAR_SALDO")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => mostrarMenu(2)}>
                    <ListItemIcon>
                        <EditIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        {Traduccion("TXT_EDITAR")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => mostrarMenu(3)}>
                    <ListItemIcon>
                        <HistoryIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        {Traduccion("TXT_HISTORICO")}
                    </ListItemText>
                </MenuItem>
            </Menu>
            <CardHeader title={Traduccion('TXT_DATOS_CAJA')}
                        action={[
                            <TooltipButton id='btnMasOpcionesCaja' key={2} label="TXT_MAS_OPCIONES" onClick={e => mostrarMenu(e)}>
                                <MoreVertIcon />
                            </TooltipButton>
                        ]}>
            </CardHeader>
            <CardContent>
                <Stack justifyContent="flex-start" useFlexGap flex={true}
                    direction={{ xs: 'column', sm: 'row', md:'column' }} 
                    alignItems={{ xs: 'center', sm: 'center', md:'flex-start' }} 
                    spacing={{xs:4, sm:6, md:2}}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Tooltip title={Traduccion('TXT_PUNTO_DE_VENTA')}>
                                <PointOfSaleIcon />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={Traduccion('TXT_PUNTO_DE_VENTA')}>
                                <div>{datosCaja.puntoDeVenta.numero}</div>
                            </Tooltip>
                        </Grid>
                        { !datosCaja.activo &&
                        <Grid item>
                            <Warn>{Traduccion('TXT_CAJA_CERRADA')}</Warn>
                        </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Tooltip title={Traduccion('TXT_CAJERO')}>
                                <BadgeIcon />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={Traduccion('TXT_CAJERO')}>
                                <div>{datosCaja.usuario.nombre}</div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Stack>
            </CardContent>
            <CardActions disableSpacing>
                <Button onClick={handleVolver} color="primary" size="small" style={{marginLeft: 'auto'}}>
                    {Traduccion('TXT_VOLVER')}
                </Button>
            </CardActions>
        </Card>
        <AjusteCaja isOpen={mostrarAjuste}
                    onClose={() => setMostrarAjuste(false)} />
        <ModificacionCaja isOpen={mostrarEdicionCaja}
                        onSubmit={handleSubmitEdicionCaja}
                        seleccion={datosCaja} />
        <HistoricoCaja mostrar={mostrarHistorico} 
                        handleClose={() => setMostrarHistorico(false)}
                        idCaja={datosCaja.id} />
    </>
}
