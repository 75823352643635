import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'

import { ResumenCierre } from './resumenCierre'

import { Acciones } from '../../utils/Acciones'

import { AutocompleteUsuario } from '../../comp/AutocompleteUsuario'
import { InputImporte } from '../../comp/Input'
import { InputFecha } from '../../comp/InputFecha'
import { InputSelect } from '../../comp/InputSelect'
import { ModalSearch } from '../../comp/ModalSearch'
import { ModalDetail } from '../../comp/ModalDetail'
import { Traduccion } from '../../comp/Traduccion'

import { UIParams } from '../../config/UIParams'

import { Acceso, NivelAcceso } from '../../utils/Seguridad'
import { abrirCaja, cerrarCaja, listarCajas, reabrirCaja, resumenDeCaja } from '../../services/servCajas'
import { listarProductos } from '../../services/servProductos'
import { getCajaOperada, setCajaOperada } from '../../config/Sesion'
import { Messages } from '../../comp/Messages2'
import { ResumenConStockToPrint } from './resumenConStock'
import { Print } from '../../utils/Printer'

export const Detalle = ({isOpen, onSubmit, seleccion, isSubmiting}) => {
    
    const navigate = useNavigate()

    const [puntoDeVenta, setPuntoDeVenta] = React.useState(1)
    const [saldoApertura, setSaldoApertura] = React.useState(0.0)
    const [accion, setAccion] = React.useState(Acciones().SEE)
    const [datosResumen, setDatosResumen] = React.useState()
    const [datosStock, setDatosStock] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)
    const [resServicio, setResServicio] = React.useState()
    const [isPrinting, setIsPrinting] = React.useState(false)
    const [cajero, setCajero] = React.useState({})

    const puedeAbrir = () => Acceso('ViewCajas', NivelAcceso().AGREGAR)
    const puedeCerrar = () => Acceso('ViewCajas', NivelAcceso().EDITAR)
    const puedeReabrir = () => Acceso('ViewCajas', NivelAcceso().BORRAR_REINSERTAR)
    
    //detectar impresion
    React.useEffect(()=>{
        if (isPrinting) {
            document.getElementById('print').click()
            setIsPrinting(false)
        }
    }, [isPrinting])

    //inicializaciones
    React.useEffect(() => {
        if (isOpen) {
            let acc = Acciones().SEE
            if (!seleccion?.id) {
                if (puedeAbrir()) {
                    acc = Acciones().ADD
                }
            } else {
                if (seleccion.activo) {
                    if (puedeCerrar()) {
                        acc = Acciones().EDIT
                    }
                } else {
                    if (puedeReabrir()) {
                        acc = Acciones().REAC
                    }
                }
            }
            setAccion(acc)
            setPuntoDeVenta(seleccion?.puntoDeVenta ?? 1)
            setSaldoApertura(seleccion?.saldoApertura)
            if (Acciones().ADD !== acc && seleccion) {
                setIsLoading(true)
                resumenDeCaja({id: seleccion.id}).then(res => {
                    let datos = []
                    res.data?.forEach(r => {
                        r.formaDePago.nombre = r.formaDePago.id < 1
                            ? Traduccion(r.formaDePago.nombre)
                            : r.formaDePago.nombre
                        let resumen = datos.find(e => e.formaDePago.id === r.formaDePago.id)
                        if (resumen) {
                            resumen.totalCobrado += r.totalCobrado
                        } else {
                            datos.push(r)
                        }
                    })
                    setDatosResumen(datos)
                    setIsLoading(false)
                })
            }
        }
    }, [seleccion, isOpen])
    
    const _handleSubmit = async (res) => {
        if (!res) return onSubmit(res)
        let cajaOp = {}
        setIsLoading(true)
        switch (accion) {
            case Acciones().ADD: { //abro la caja
                cajaOp.puntoDeVenta = {id: puntoDeVenta}
                cajaOp.saldoApertura = saldoApertura
                let res = await abrirCaja(cajaOp)
                if (res.errores) {
                    setResServicio(res)
                } else {
                    cajaOp.id = res.data.id
                    cajaOp.activo = true
                    cajaOp.puntoDeVenta.numero = '00001'
                    res = await listarCajas({id: cajaOp.id})
                    cajaOp.usuario = res.data[0].usuario
                    navigate('/cajas/movimientos/nuevo')
                }
                break
            }
            case Acciones().EDIT:  { //cierro la caja
                cajaOp = getCajaOperada()
                let res = await cerrarCaja({id: cajaOp.id})
                if (res.errores) {
                    setResServicio(res)
                } else {
                    let resProd = await listarProductos({soloStock: true})
                    setDatosStock(resProd.data)
                    setCajero(cajaOp.usuario)
                    setIsPrinting(true)
                    setTimeout(() => navigate('/cajas'), 100)
                }
                break
            }
            case Acciones().REAC: { //reabro la caja
                let res = await reabrirCaja({id: getCajaOperada().id})
                if (res.errores) {
                    setResServicio(res)
                } else {
                    cajaOp = getCajaOperada()
                    cajaOp.activo = true
                    navigate('/cajas/movimientos/nuevo')
                }
                break
            }
            default: break
        }
        setCajaOperada(cajaOp)
        setIsLoading(false)
    }
    
    let titulo = ''
    switch (accion) {
        case Acciones().ADD: titulo = 'TXT_ABRIR_CAJA'; break;
        case Acciones().EDIT: titulo = 'TXT_CERRAR_CAJA'; break;
        case Acciones().REAC: titulo = 'TXT_REABRIR_CAJA'; break;
        case Acciones().SEE: titulo = 'TXT_DET_CIERRE_CAJA'; break;
        default: titulo = 'TXT_ABRIR_CAJA'
    }

    return <>
        <ModalDetail accion={accion} isOpen={isOpen} onSubmit={_handleSubmit} customTitle={titulo} isLoading={isSubmiting}>
            <Grid container spacing={2}>
            { Acciones().ADD === accion &&
                <>
                <Grid item xs={12}>
                    <InputSelect id='puntoDeVentaAbrirCaja'
                                    label={Traduccion('TXT_PUNTO_DE_VENTA')}
                                    value={puntoDeVenta}
                                    onChange={v => setPuntoDeVenta(v)}
                                    options={[
                                        {label: '00001', value: 1}
                                    ]}/>
                </Grid>
                <Grid item xs={12}>
                    <InputImporte id='saldoAperturaAbrirCaja'
                                    label={Traduccion('TXT_SALDO')}
                                    value={saldoApertura}
                                    onChange={e => setSaldoApertura(e)}
                                    autoFocus={true}/>
                </Grid>
                </>
            }
            { (Acciones().ADD !== accion && seleccion) &&
                <Grid item xs={12}>
                    <ResumenCierre datos={datosResumen} isLoading={isLoading}/>
                </Grid>
            }
            </Grid>
            <Messages resServicio={resServicio} accion={Acciones().ADD} />
        </ModalDetail>
        {isPrinting &&
        <Print id='print'>
            <ResumenConStockToPrint datosCajero={[cajero]} datosCaja={datosResumen} datosStock={datosStock} />
        </Print>
        }
    </>
}

//------------------

export const Filtros = ({values, isOpen, onSubmit}) => {

    const [user, setUser] = React.useState(values.user)
    const [desde, setDesde] = React.useState(values.desde)
    const [hasta, setHasta] = React.useState(values.hasta)
    
    const puedeBuscarUsuario = Acceso('ViewCajas', NivelAcceso().CONTROLAR_TODO)

    const _handleSubmit = (submit) => {
        if (!submit) {
            setUser(values.user)
            setDesde(values.desde)
            setHasta(values.hasta)
        }
        onSubmit(submit, {user: user, desde: desde, hasta: hasta})
    }

    const _handleSelectUsuario = (seleccion) => {
        seleccion ? setUser(seleccion) : setUser('')
    }

    return <ModalSearch isOpen={isOpen} onSubmit={res => _handleSubmit(res)}>
        <Grid container spacing={2} 
              style={{maxWidth: UIParams().modalSearchMaxWidth}}>
        { puedeBuscarUsuario &&
            <Grid item xs={12}>
                <AutocompleteUsuario autoFocus
                                     seleccion={user}
                                     onSelect={_handleSelectUsuario}/>
            </Grid>
        }
            <Grid item xs={12}>
                <InputFecha label={Traduccion('TXT_FECHA_DESDE')} 
                            value={desde}
                            onChange={fDesde => setDesde(fDesde)}/>
            </Grid>
            <Grid item xs={12}>
                <InputFecha label={Traduccion('TXT_FECHA_HASTA')} 
                            value={hasta}
                            onChange={fHasta => setHasta(fHasta)}/>
            </Grid>
        </Grid>
    </ModalSearch>
}