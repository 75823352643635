import React from 'react'
import { Card, CardHeader, Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Warn } from '../../comp/Message'
import { Traduccion } from '../../comp/Traduccion'

import { MesaCard } from './mesa'

import { listarMesas } from '../../services/servMesas'
import { setCajaOperada, User } from '../../config/Sesion'
import { listarCajas } from '../../services/servCajas'

export const Mesas = () => {

    const [isLoading, setIsLoading] = React.useState(true)
    const [mesas, setMesas] = React.useState([])
    const [debeAbrirUnaCaja, setDebeAbrirUnaCaja] = React.useState(false)

    const hayError = () => !isLoading && (debeAbrirUnaCaja || !mesas.length)

    React.useEffect(() => {
        let params = {user: { id: User().id }, activo: true}
        listarCajas(params).then(data => {
            if (!data.length) {
                setDebeAbrirUnaCaja(true)
                setIsLoading(false)
                return
            }
            setCajaOperada(data[0])
            listarMesas().then(res => {
                setMesas(res.data)
                setIsLoading(false)
            })
        })
    }, [])

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card><CardHeader title={Traduccion('TXT_GESTION_DE_MESAS')} /></Card>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            {isLoading
                ? <LoadingTables />
                : hayError()
                    ? <Grid item xs={12}><Warn>{Traduccion(debeAbrirUnaCaja ? 'ADV_SIN_CAJA_ACTIVA' : 'ADV_MESAS_NO_CONFIGURADAS')+'.'}</Warn></Grid>
                    : mesas.map(mesa =>
                        <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={mesa.id}>
                            <MesaCard idMesa={mesa.id} nroMesa={mesa.nroMesa} mesaOcupada={mesa.ocupada} />
                        </Grid>
                    )}
        </Grid>
    </div>
}

const LoadingTables = () => {
    let a = [1, 2]
    return a.map(e =>
        <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={e}>
            <Skeleton variant="rect" animation="wave" height={'14.4em'} />
        </Grid>
    )
}