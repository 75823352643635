import React from 'react'
import { Grid } from '@mui/material'
import { InputTexto } from '../../../comp/Input'
import { ModalDetail } from '../../../comp/ModalDetail'
import { Traduccion } from '../../../comp/Traduccion'

export const Detalle = ({ seleccion = { nombre: null }, 
    accion, isOpen, onSubmit }) => {

    const [nombre, setNombre] = React.useState(seleccion.nombre)
    const [formSubmited, setFormSubmited] = React.useState(false)

    React.useEffect(() => {
        if (isOpen) {
            setNombre(seleccion.nombre)
            setFormSubmited(false)
        }
    }, [seleccion, isOpen])

    const obligatoriosConValor = () => (nombre ? true : false)

    const _handleSubmit = (res) => {
        if (res && !obligatoriosConValor()) {
            setFormSubmited(true)
            return //si intento enviar form y los obligatorios no están completos interrumpo
        }
        onSubmit(res, { id: seleccion.id, nombre: nombre, editable: true })
    };

    return <ModalDetail accion={accion} isOpen={isOpen} onSubmit={_handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputTexto label={Traduccion('TXT_NOMBRE')}
                    value={nombre}
                    onChange={e => setNombre(e)}
                    validarSubmit={formSubmited}
                    requerido/>
            </Grid>
        </Grid>
    </ModalDetail>
}