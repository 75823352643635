import React from 'react'
import { Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

import { InputImporte } from '../../../comp/Input'
import { InputSelect } from '../../../comp/InputSelect'
import { ModalDetail } from '../../../comp/ModalDetail'
import { Traduccion } from '../../../comp/Traduccion'
import { Warn } from '../../../comp/Message'

import { Acciones } from '../../../utils/Acciones'

import { getCajaOperada } from '../../../config/Sesion'
import { AutocompleteCliente } from '../../../comp/AutocompleteCliente'
import { agregarMovimiento, listarFormasDePago, listarMotivosMovimiento } from '../../../services/servCajas'
import { Messages } from '../../../comp/Messages2'

export const AjusteCaja = ({ isOpen, onClose }) => {

    const [motivos, setMotivos] = React.useState([])
    const [motivoId, setMotivoId] = React.useState('')
    const [cliente, setCliente] = React.useState(null)
    const [isLoadingMotivos, setIsLoadingMotivos] = React.useState(false)
    const [ajustes, setAjustes] = React.useState([])
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [resServicio, setResServicio] = React.useState()

    const handleSubmit = (res) => {
        if (!res) {
            onClose(false)
            return
        }
        if (motivoId === -3 && !cliente) {
            alert(Traduccion('ERR_FALTA_CLIENTE'))
            return
        }
        var ajuste = {
            caja: getCajaOperada(),
            motivoMovimiento: { id: motivoId },
            detallesProductos: [],
            detallesCobros: ajustes,
            cliente: cliente
        }
        setIsSubmitting(true)
        setResServicio()
        agregarMovimiento(ajuste).then(resp => { //despues de ajustar se tiene que actualizar la lista de movimientos (si es que está en esa pantalla)
            setIsSubmitting(false)
            setResServicio(resp)
            !resp.errores && onClose(true)
        })
    }

    const getMotivos = () => {
        if (motivos.length === 0) {
            return [{ label: Traduccion('TXT_SELECCIONE'), value: '' }]
        }
        return motivos.map(m => { return { label: m.nombre, value: m.id } })
    }

    const changeMotivoIdEvent = (idMotivo) => {
        setMotivoId(idMotivo)
        setAjustes(motivos
            .find(e => e.id === idMotivo)
            .formasDePago
            .map(e => {
                return { formaDePago: e, importe: 0.0 }
            })
        )
    }

    const setTipoCobroVal = (val, formaDePagoId) => {
        var t = [...ajustes]
        t.find(e => e.formaDePago.id === formaDePagoId.split('_')[1] * 1).importe = val
        setAjustes(t)
    }

    //Inicializo la lista de motivos
    React.useEffect(() => {
        setIsLoadingMotivos(true)
        listarMotivosMovimiento({ activo: true, incluirFormasDePago: true }).then(res => {
            //saco del listado la "Venta por caja" y el "Cobro en mesa" (no son ajustes)
            let aux = res.data.filter(e => ![-2, 1].includes(e.id))
            aux.forEach(e => {
                if (e.id < 1) {
                    e.nombre = Traduccion(e.nombre)
                    if (e.id === -3) { //si es cobro por cta cte
                        listarFormasDePago({ activo: true }).then(res => {
                            //agrego todas las formas de pago, excepto la de "cta cte"
                            e.formasDePago = res.data.filter(tc => tc.id !== -2)
                        })
                    }
                }
            })
            aux.sort((mm1, mm2) => mm1.nombre.localeCompare(mm2.nombre))
            setMotivos(aux)
            if (aux.length > 0) {
                setMotivoId(aux[0].id)
                setAjustes(aux[0].formasDePago
                    .map(e => {
                        return { formaDePago: e, importe: 0.0 }
                    })
                )
            } else {
                setMotivoId('')
            }
            setIsLoadingMotivos(false)
        })
    }, [])

    React.useEffect(() => {
        if (isOpen) {
            setResServicio()
            // ya tengo elementos cargados, selecciono el primero si está disponible
            if (motivos.length > 0) {
                setMotivoId(motivos[0].id)
                setCliente(null)
                setAjustes(motivos[0].formasDePago
                    .map(e => {
                        return { formaDePago: e, importe: 0.0 }
                    })
                )
            }
        }
    }, [isOpen, motivos])

    return <>
        <ModalDetail isOpen={isOpen}
            onSubmit={handleSubmit}
            accion={motivos.length > 0 ? Acciones().ADD : Acciones().SEE}
            maxWidth="xs"
            customTitle="TXT_AJUSTAR_SALDO"
            isLoading={isLoadingMotivos || isSubmitting}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputSelect label={Traduccion('TXT_MOTIVO')}
                        value={motivoId}
                        onChange={v => changeMotivoIdEvent(v)}
                        options={getMotivos()}
                        isLoading={isLoadingMotivos}
                        editable={!isLoadingMotivos && motivos.length > 0 && !isSubmitting} />
                </Grid>
                {motivoId === -3 &&
                    <>
                        <Grid item xs={12}>
                            <AutocompleteCliente id='autocompClienteAjuste'
                                requerido
                                seleccion={cliente}
                                onSelect={setCliente}
                                error={!cliente}
                                validarSubmit={true}
                                editable={!isSubmitting}
                                autoFocus />
                        </Grid>
                        <Grid item xs={6}>
                            <InputImporte id='saldo_clienteAjuste'
                                label={Traduccion('TXT_SALDO')}
                                value={cliente ? cliente.saldo : 0.0}
                                editable={false} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputImporte id='disponible_clienteAjuste'
                                label={Traduccion('TXT_CREDITO_DISPONIBE')}
                                value={cliente ? cliente.topeCredito + cliente.saldo : 0.0}
                                editable={false} />
                        </Grid>
                    </>
                }
                {isLoadingMotivos &&
                    <Skeleton variant="rect" animation="wave" height={'1.5em'} />
                }
                {!isLoadingMotivos && motivos.length === 0 &&
                    <Warn>{Traduccion('ADV_CONFIGURAR_MOTIVOS_TIPOS_COBRO')}.</Warn>
                }
                {
                    ajustes.map(e => <Grid key={e.formaDePago.id} item xs={12} md={ajustes.length === 1 ? 12 : 6}>
                        <InputImporte label={e.formaDePago.id < 1 ? Traduccion(e.formaDePago.nombre) : e.formaDePago.nombre}
                            id={`tipoCobro_${e.formaDePago.id}`}
                            value={e.importe}
                            onChange={setTipoCobroVal}
                            autoFocus={false}
                            editable={!isSubmitting} />
                    </Grid>
                    )
                }
            </Grid>
        </ModalDetail>
        <Messages resServicio={resServicio} accion={Acciones().ADD} />
    </>
}