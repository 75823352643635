import React from "react"
import { Grid } from '@mui/material'
import { InputTexto } from "../../../../comp/Input"
import { InputSelect } from "../../../../comp/InputSelect"
import { ModalDetail } from "../../../../comp/ModalDetail"
import { Traduccion } from "../../../../comp/Traduccion"
import { listarFormasDePago } from "../../../../services/servCajas"

export const Detalle = ({ accion, isOpen, onSubmit, seleccion = {} }) => {

    const [nombre, setNombre] = React.useState(seleccion.nombre)
    const [signo, setSigno] = React.useState(seleccion.signo)
    const [activo, setActivo] = React.useState(seleccion.activo)
    const [formasDePago, setFormasDePago] = React.useState([])
    const [formSubmited, setFormSubmited] = React.useState(false)

    const [formasDePagoActivas, setFormasDePagoActivas] = React.useState([])

    const obligatoriosConValor = () => nombre !== undefined && nombre !== null && nombre.length &&
        formasDePago !== undefined && formasDePago !== null && formasDePago.length

    const handleSubmit = (res) => {
        if (res && !obligatoriosConValor()) {
            setFormSubmited(true)
            return //si intento enviar form y los obligatorios no están completos interrumpo
        }
        onSubmit(res, {
            id: seleccion.id,
            nombre,
            signo,
            activo,
            formasDePago: formasDePagoActivas.filter(f => formasDePago.includes(f.id)).map(f => ({...f, relationId: seleccion.formasDePago?.find(f1 => f1.id === f.id)?.relationId}))
        })
    }

    const formatOpcionesFormasDePago = () => 
        formasDePagoActivas.map(e => ({
            label: e.id < 1 ? Traduccion(e.nombre) : e.nombre,
            value: e.id
        }))

    React.useEffect(() => {
        if (isOpen) {
            setNombre(seleccion.nombre)
            setSigno(seleccion.signo ? seleccion.signo : 1)
            setActivo(seleccion.activo)
            setFormasDePago(seleccion.formasDePago ? seleccion.formasDePago.map(e => e.id) : [])
            setFormSubmited(false)
            if (!formasDePagoActivas.length) {
                listarFormasDePago({ activo: true }).then(res => {
                    //res.data.forEach(e => e.nombre = e.id < 1 ? Traduccion(e.nombre) : e.nombre)
                    res.data.sort((e1, e2) => e1.nombre.localeCompare(e2.nombre))
                    setFormasDePagoActivas(res.data)
                })
            }
        }
    }, [seleccion, isOpen, formasDePagoActivas]);

    return <ModalDetail accion={accion} isOpen={isOpen} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputTexto label={Traduccion('TXT_NOMBRE')}
                    value={nombre}
                    onChange={e => setNombre(e)}
                    validarSubmit={formSubmited}
                    requerido autoFocus />
                <InputSelect label={Traduccion('TXT_TIPO_MOVIMIENTO')}
                    value={signo}
                    onChange={v => setSigno(v)}
                    options={[{ label: Traduccion('TXT_INGRESO'), value: 1 }, { label: Traduccion('TXT_EGRESO'), value: -1 }]} />
                <InputSelect label={Traduccion('TXT_FORMA_DE_PAGO')}
                    value={formasDePago}
                    onChange={v => setFormasDePago(v)}
                    isLoading={!formasDePagoActivas.length}
                    options={formatOpcionesFormasDePago()}
                    validarSubmit={formSubmited}
                    multiple requerido />
            </Grid>
        </Grid>
    </ModalDetail>
}