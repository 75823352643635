import React from 'react'
import { DataTable } from '../../comp/DataTableSimple'

import { Tipos } from '../../utils/Tipos'

export const ResumenCierre = ({datos, isLoading, toPrint = false}) => {
    return <DataTable headers={['TXT_FORMA_DE_PAGO', 'TXT_IMPORTE']}
               types={[Tipos().DESC, Tipos().IMP, Tipos().IMP, Tipos().IMP]}
               access={['formaDePago.nombre', 'totalCobrado']}
               data={datos}
               isLoading={isLoading}
               totalizar={-1}
               size={toPrint ? 'small' : undefined}/>
}