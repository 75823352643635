import { UrlApi } from '../config/Services'
import { DeleteCall, GetCall, PatchCall, PostCall, PutCall } from './_serviceHelper2'

const serviceUrl = `${UrlApi()}/productos`

export const reportePoductosValorYStockPorCategoria = () => {
    return GetCall(serviceUrl + '/reportes/productosValorYStockPorCategoria')
}

export const reportePreciosObsoletos = () => {
    return GetCall(serviceUrl + '/reportes/preciosObsoletos')
}

export const listarProductos = (filtros) => {
    let params = {
        codigo: filtros?.codigo,
        nombre: filtros?.nombre,
        categoriaId: filtros?.categoriaId,
        activo: filtros?.activo,
        soloStock: filtros?.soloStock,
        pageNumber: filtros?.pageNumber,
        pageSize: filtros?.pageSize
    }
    return GetCall(serviceUrl, params)
}

export const autocompletarProducto = (texto) => {
    return GetCall(serviceUrl + '/autocompletar', {texto, activo: true})
}

export const agregarProducto = (unProducto) => PostCall(serviceUrl, unProducto)
export const editarProducto = (unProducto) => PutCall(serviceUrl, unProducto)
export const borrarProductos = (ids) => DeleteCall(serviceUrl, {ids})
export const reactivarProductos = (ids) => PatchCall(serviceUrl, {ids})
export const buscarSiguienteCodigoDisponible = () => GetCall(serviceUrl+'/codigoLibre', {})