export function Traduccion (key) {
    let hayRes = diccionario.find(e => e.key === key);
    return hayRes ? hayRes.text : 'TEXTO INTRADUCIBLE: ' + key;
};

var diccionario = [
    {'key':'ViewClientes', 'text':'Clientes'},
    {'key':'ViewCajas', 'text':'Cajas'},
    {'key':'ViewProductosOfrecidos', 'text':'Productos ofrecidos por'},
    {'key':'ViewProveedores', 'text':'Proveedores'},
    {'key':'ViewProductos', 'text':'Productos'},
    {'key':'ViewOrdenesDeCompra', 'text':'Órdenes de compra'},
    {'key':'ViewCobros', 'text':'Cobros'},
    {'key':'ViewRemitos', 'text':'Remitos'},
    {'key':'ViewSaldosClientes', 'text':'Saldos clientes'},
    {'key':'ViewRoles', 'text':'Roles'},
    {'key':'ViewRolesVistas', 'text':'Permisos'},
    {'key':'ViewUsuarios', 'text':'Usuarios'},
    {'key':'ViewPedidosProveedores', 'text':'Pedidos a proveedores'},
    {'key':'ViewVentasPorCaja', 'text':'Venta por caja'},
    {'key':'ViewMovimientosCajas', 'text':'Movimientos de cajas'},
    {'key':'ViewMiEmpresa', 'text':'Mi empresa'},
    {'key':'ViewReportesCaja', 'text':'Reportes'},
    {'key':'ViewReportes', 'text':'Reportes'},
    {'key':'ViewCategoriasProductos', 'text':'Categorías de productos'},
    {'key':'ViewConfiguraciones', 'text':'Configuraciones'},
    {'key':'ViewMesas', 'text':'Mesas'},
    {'key':'ViewClientes', 'text':'Clientes'},
    {'key':'ViewUsuarios', 'text':'Usuarios'},

    {'key':'MODULO_PRODUCTOS', 'text':'Módulo Productos'},
    {'key':'MODULO_VENTAS', 'text':'Módulo Ventas'},
    {'key':'MODULO_AFIP', 'text':'Módulo Afip'},
    {'key':'MODULO_CLIENTES', 'text':'Módulo Clientes'},

    {'key':'TXT_CODIGO', 'text':'Código'},
    {'key':'TXT_DESCRIPCION', 'text':'Descripción'},
    {'key':'TXT_STOCK', 'text':'Stock'},
    {'key':'TXT_DISPONIBLE', 'text':'Disponible'},
    {'key':'TXT_OCUPADA', 'text':'Ocupada'},
    {'key':'TXT_CON_PESAJE', 'text':'Con pesaje'},
    {'key':'TXT_COSTO', 'text':'Costo'},
    {'key':'TXT_COSTO_MIN', 'text':'P. Costo'},
    {'key':'TXT_VENTA', 'text':'Venta'},
    {'key':'TXT_VENTAS', 'text':'Ventas'},
    {'key':'TXT_VENTA_MIN', 'text':'P. Venta'},
    {'key':'TXT_PREGUNTA_REQUIERE_PESAJE', 'text':'¿El producto requiere pesaje?'},
    {'key':'TXT_PREGUNTA_ADMITE_DEVOLUCION', 'text':'¿El producto admite devolución?'},
    {'key':'TXT_FECHA_ALTA', 'text':'Fecha alta'},
    {'key':'TXT_FECHA_BAJA', 'text':'Fecha baja'},
    {'key':'TXT_GENERAR_REMITO', 'text':'Generar remito'},
    {'key':'TXT_USUARIO', 'text':'Usuario'},
    {'key':'TXT_USUARIO_NO_LOGUEADO', 'text':'Usuario deslogueado'},
    {'key':'TXT_RAZON_SOCIAL', 'text':'Razón social'},
    {'key':'TXT_NOMBRE_FANTASIA', 'text':'Nombre fantasía'},
    {'key':'TXT_CUIT', 'text':'CUIT'},
    {'key':'TXT_FECHA', 'text':'Fecha'},
    {'key':'TXT_TOTAL', 'text':'Total'},
    {'key':'TXT_COBRADOR', 'text':'Cobrador'},
    {'key':'TXT_CLIENTE', 'text':'Cliente'},
    {'key':'TXT_PROVEEDOR', 'text':'Proveedor'},
    {'key':'TXT_FECHA_DESDE', 'text':'Fecha desde'},
    {'key':'TXT_FECHA_HASTA', 'text':'Fecha hasta'},
    {'key':'TXT_AUTOCOMPLETE', 'text':'Empiece a escribir...'},
    {'key':'TXT_REQUERIDO', 'text':'Campo requerido'},
    {'key':'TXT_AGREGAR', 'text':'Agregar'},
    {'key':'TXT_EDITAR', 'text':'Editar'},
    {'key':'TXT_VER', 'text':'Ver'},
    {'key':'TXT_BORRAR', 'text':'Borrar'},
    {'key':'TXT_REACTIVAR', 'text':'Reactivar'},
    {'key':'TXT_IMPORTE', 'text':'Importe'},
    {'key':'TXT_SELECCIONE', 'text':'- Seleccione -'},
    {'key':'TXT_TODAS', 'text':'Todas'},
    {'key':'TXT_SELECCION_TODOS', 'text':'- Todos -'},
    {'key':'TXT_SELECCION_TODAS', 'text':'- Todas -'},
    {'key':'TXT_SELECCION', 'text':'Selección'},
    {'key':'TXT_DETALLES_DEL_COBRO', 'text':'Detalles del cobro'},
    {'key':'TXT_DETALLES_DEL_PEDIDO', 'text':'Detalles del pedido'},
    {'key':'TXT_DETALLES_DEL_REMITO', 'text':'Detalles del remito'},
    {'key':'TXT_PRODUCTO', 'text':'Producto'},
    {'key':'TXT_CANTIDAD', 'text':'Cantidad'},
    {'key':'TXT_PRECIO_UNITARIO', 'text':'P. Unitario'},
    {'key':'TXT_SUBTOTAL', 'text':'Subtotal'},
    {'key':'TXT_CANTIDAD_INSUFICIENTE', 'text':'Cantidad insuficiente'},
    {'key':'TXT_CREAR_REMITO', 'text':'Crear Remito'},
    {'key':'TXT_VENDIDO', 'text':'Vendido'},
    {'key':'TXT_COBRADO', 'text':'Cobrado'},
    {'key':'TXT_SALDO', 'text':'Saldo'},
    {'key':'TXT_SALDO_APERTURA', 'text':'Saldo apertura'},
    {'key':'TXT_SALDO_ACTUAL', 'text':'Saldo actual'},
    {'key':'TXT_SALDO_FINAL', 'text':'Saldo final'},
    {'key':'TXT_ACTIVOS', 'text':'Activos'},
    {'key':'TXT_INACTIVOS', 'text':'Inactivos'},
    {'key':'TXT_ESTADO', 'text':'Estado'},
    {'key':'TXT_ROLES', 'text':'Roles'},
    {'key':'TXT_PERMISOS', 'text':'Permisos'},
    {'key':'TXT_LIMITE_CUENTA_CORRIENTE', 'text':'Límite cta. cte.'},
    {'key':'TXT_CUENTA_CORRIENTE', 'text':'Cuenta corriente'},
    {'key':'TXT_NOMBRE', 'text':'Nombre'},
    {'key':'TXT_CORREO', 'text':'Correo'},
    {'key':'TXT_PASS', 'text':'Contraseña'},
    {'key':'TXT_LISTA_ROLES', 'text':'Lista de roles'},
    {'key':'TXT_COMPRADOR', 'text':'Comprador'},
    {'key':'TXT_VENDEDOR', 'text':'Vendedor'},
    {'key':'TXT_DETALLES_DE_LA_COMPRA', 'text':'Detalles de la compra'},
    {'key':'TXT_PRECIO', 'text':'Precio'},
    {'key':'TXT_RECIBIR', 'text':'Recibir'},
    {'key':'TXT_RECEPCION_PEDIDO', 'text':'Recepción de pedido'},
    {'key':'TXT_PREG_RECIBIDO', 'text':'¿Recibido?'},
    {'key':'TXT_NRO', 'text':'Nro.'},
    {'key':'TXT_DESCARGAR_PDF', 'text':'Descargar PDF'},
    {'key':'TXT_DESCARGAR', 'text':'Descargar'},
    {'key':'TXT_DOMICILIO', 'text':'Domicilio'},
    {'key':'TXT_LOCALIDAD', 'text':'Localidad'},
    {'key':'TXT_CONDICION_IVA', 'text':'Condición IVA'},
    {'key':'TXT_NOMBRE_O_RAZON_SOCIAL', 'text':'Nombre o Razón Social'},
    {'key':'TXT_COMPROBANTE_NO_VALIDO_COMO_FACTURA', 'text':'Comprobante no válido como factura'},
    {'key':'TXT_FECHA_INICIO_DE_ACT', 'text':'Inicio de act.'},
    {'key':'TXT_REMITO', 'text':'Remito'},
    {'key':'TXT_IMPRIMIR', 'text':'Imprimir'},
    {'key':'TXT_PREGUNTA_ACTIVO', 'text':'¿Activo?'},
    {'key':'TXT_SITUACION_IMPOSITIVA', 'text':'Sit. Impositiva'},
    {'key':'TXT_RESTANTE', 'text':'restante'},
    {'key':'TXT_RESTANTES', 'text':'restantes'},
    {'key':'TXT_BUSCAR', 'text':'Buscar'},
    {'key':'TXT_FILTRAR', 'text':'Filtrar'},
    {'key':'TXT_GUARDAR', 'text':'Guardar'},
    {'key':'TXT_CANCELAR', 'text':'Cancelar'},
    {'key':'TXT_VOLVER', 'text':'Volver'},
    {'key':'TXT_SIN_OPCIONES', 'text':'-- Sin opciones --'},
    {'key':'TXT_PRODUCTOS_DE_VENTA_EN_CAJA', 'text':'Productos'},
    {'key':'TXT_FORMA_DE_PAGO', 'text':'Forma de pago'},
    {'key':'TXT_FORMAS_DE_PAGO', 'text':'Formas de pago'},
    {'key':'TXT_FORMAS_DE_COBRO', 'text':'Formas de cobro'},
    {'key':'TXT_RESUMEN', 'text':'Resumen'},
    {'key':'TXT_TOTAL_VENDIDO', 'text':'Total vendido'},
    {'key':'TXT_TOTAL_COBRADO', 'text':'Total cobrado'},
    {'key':'TXT_VUELTO', 'text':'Vuelto'},
    {'key':'TXT_MOSTRAR_FORMAS_DE_PAGO', 'text':'Formas de pago'},
    {'key':'TXT_VOLVER_A_PRODUCTOS', 'text':'Volver a productos'},
    {'key':'TXT_PRODUCTOS', 'text':'Productos'},
    {'key':'TXT_DATOS_CAJA', 'text':'Datos caja'},
    {'key':'TXT_CAJERO', 'text':'Cajero'},
    {'key':'TXT_ABRIR_CAJA', 'text':'Abrir caja'},
    {'key':'TXT_CERRAR_CAJA', 'text':'Cerrar caja'},
    {'key':'TXT_REABRIR_CAJA', 'text':'Reabrir caja'},
    {'key':'TXT_REABRIR', 'text':'Reabrir'},
    {'key':'TXT_COMENTARIOS', 'text':'Comentarios'},
    {'key':'TXT_PUNTOS_DE_VENTA', 'text':'Puntos de venta'},
    {'key':'TXT_PUNTO_DE_VENTA', 'text':'Punto de venta'},
    {'key':'TXT_FECHA_APERTURA', 'text':'Fecha apertura'},
    {'key':'TXT_CAJA_ABIERTA', 'text':'Caja abierta'},
    {'key':'TXT_CAJA_CERRADA', 'text':'Caja cerrada'},
    {'key':'TXT_ABIERTA', 'text':'Abierta'},
    {'key':'TXT_CERRADA', 'text':'Cerrada'},
    {'key':'TXT_INICIAR_SESION', 'text':'Iniciar sesión'},
    {'key':'TXT_CERRAR_SESION', 'text':'Cerrar sesión'},
    {'key':'TXT_TICKET_NRO', 'text':'Ticket Nro.'},
    {'key':'TXT_SU_PAGO', 'text':'Su pago'},
    {'key':'TXT_SU_VUELTO', 'text':'Su vuelto'},
    {'key':'TXT_GRACIAS_POR_SU_COMPRA', 'text':'¡Gracias por su compra!'},
    {'key':'TXT_HISTORICO', 'text':'Histórico'},
    {'key':'TXT_INFORMACION_CIERRE', 'text':'Información de cierre'},
    {'key':'TXT_TIPO_MOVIMIENTO', 'text':'Tipo de movimiento'},
    {'key':'TXT_APERTURA', 'text':'Apertura'},
    {'key':'TXT_CIERRE', 'text':'Cierre'},
    {'key':'TXT_DET_CIERRE_CAJA', 'text':'Detalle del cierre de caja'},
    {'key':'TXT_ANTERIOR', 'text':'Anterior'},
    {'key':'TXT_SIGUIENTE', 'text':'Siguiente'},
    {'key':'TXT_MIS_SERVICIOS', 'text':'Mis servicios'},
    {'key':'TXT_CARRITO', 'text':'Ir al carrito'},
    {'key':'TXT_PERIODO', 'text':'Período'},
    {'key':'TXT_GANANCIA', 'text':'Ganancia'},
    {'key':'TXT_DIFERENCIA', 'text':'Diferencia'},
    {'key':'TXT_VARIACION', 'text':'Variación'},
    {'key':'TXT_RENOVAR_AHORA', 'text':'Renovar ahora'},
    {'key':'TXT_PAGAR', 'text':'Pagar'},
    {'key':'TXT_CATEGORIA', 'text':'Categoría'},
    {'key':'TXT_CARGANDO', 'text':'Cargando'},
    {'key':'TXT_MAS_OPCIONES', 'text':'Más opciones'},
    {'key':'TXT_AJUSTAR_SALDO', 'text':'Ajustar'},
    {'key':'TXT_AJUSTE_DEUDOR', 'text':'Ajuste por saldo deudor'},
    {'key':'TXT_AJUSTE_ACREEDOR', 'text':'Ajuste por saldo acreedor'},
    {'key':'TXT_MOTIVO', 'text':'Motivo'},
    {'key':'TXT_NUMERO', 'text':'Número'},
    {'key':'TXT_MOTIVOS_MOVIMIENTO', 'text':'Motivos de movimiento'},
    {'key':'TXT_INGRESO', 'text':'Ingreso'},
    {'key':'TXT_EGRESO', 'text':'Egreso'},
    {'key':'TXT_CONFIGURAR', 'text':'Configurar'},
    {'key':'TXT_ACTIVAR', 'text':'Activar'},
    {'key':'TXT_ASISTENTE_CONFIGURACION', 'text':'Asistente de configuración'},
    {'key':'TXT_ACEPTO_COSTOS', 'text':'Entiendo que aumentará el costo mensual'},
    {'key':'TXT_ALIAS', 'text':'Alias'},
    {'key':'TXT_ADJUNTAR', 'text':'Adjuntar'},
    {'key':'TXT_SUBIR', 'text':'Subir'},
    {'key':'TXT_NINGUN_ARCHIVO_SELECCIONADO', 'text':'Ningun archivo seleccionado'},
    {'key':'TXT_FINALIZAR', 'text':'Finalizar'},
    {'key':'TXT_CONSUMOS', 'text':'Consumos'},
    {'key':'TXT_CONSUMOS_MESA_NRO', 'text':'Consumos mesa nro.'},
    {'key':'TXT_CUENTA_MESA_NRO', 'text':'Cuenta mesa nro.'},
    {'key':'TXT_COBRAR_MESA_NRO', 'text':'Cobrar mesa nro.'},
    {'key':'TXT_GESTION_DE_MESAS', 'text':'Gestión de mesas'},
    {'key':'TXT_AUMENTAR', 'text':'Aumentar'},
    {'key':'TXT_DISMINUIR', 'text':'Disminuir'},
    {'key':'TXT_VER_CUENTA', 'text':'Ver cuenta'},
    {'key':'TXT_COBRAR', 'text':'Cobrar'},
    {'key':'TXT_BUSCAR_SIG_CODIGO', 'text':'Buscar sig. código'},
    {'key':'TXT_TOPE_CREDITO', 'text':'Límite de crédito'},
    {'key':'TXT_CREDITO_DISPONIBE', 'text': 'Disponible'},
    {'key':'TXT_TRANSACCIONES', 'text': 'Transacciones'},
    {'key':'TXT_TIPO_TRANSACCION', 'text':'Tipo transacción'},
    {'key':'TXT_COBRO_POR_CAJA', 'text':'Cobro por caja'},
    {'key':'TXT_REVERSO_POR_ERROR', 'text':'Reverso por error'},
    {'key':'TXT_VENTA_POR_CAJA', 'text':'Venta por caja'},
    {'key':'TXT_PAGINA_ABREV', 'text':'Pág.'},
    {'key':'TXT_NOMBRES', 'text':'Nombres'},
    {'key':'TXT_APELLIDOS', 'text':'Apellidos'},
    {key:'TXT_REGISTRAR_VENTA', text:'Registrar Venta'},
    {key:'TXT_EFECTIVO', text:'Efectivo'},

    //clientes
    {key:'TXT_COBRO_POR_CC', text:'Cobros por cuenta corriente'},
    {key:'TXT_EVOLUCION_CLIENTES', text:'Evolución de clientes'},
    {key:'TXT_CLIENTE', text:'cliente'},
    {key:'TXT_CLIENTES', text:'clientes'},
    {key:'TXT_VIEJOS', text:'Viejos'},
    {key:'TXT_NUEVOS', text:'Nuevos'},
    {key:'TXT_CLIENTES_MAS_GASTADORES', text:'Clientes más gastadores'},
    {key:'TXT_CLIENTES_MAS_DEUDORES', text:'Clientes más deudores'},
    {key:'TXT_ESTADO_CTA_CTE', text:'Estado de Cta. Cte.'},
    {key:'TXT_AJUSTE_SALDO_INICIAL', text:'Ajuste por saldo inicial'},

    //mesas
    {'key':'MMC_COBRO_MESA', 'text':'Consumo en mesas'},

    //cajas
    {key:'TXT_CANTIDAD_VENTAS_CONCRETADAS', text:'Cantidad ventas concretadas'},
    {key:'TXT_INGRESOS_POR_VENTAS', text: 'Ingresos por ventas'},
    {key:'TXT_PRODUCTOS_MAS_VENDIDOS', text:'Productos mas vendidos'},
    {key:'TXT_PRODUCTOS_MENOS_VENDIDOS', text:'Productos menos vendidos'},
    {key:'TXT_CANTIDAD_UN', text:'Cantidad (un.)'},
    {key:'TXT_UNIDADES', text:'Unidades'},
    {key:'TXT_UNIDAD', text:'Unidad'},
    {key:'TXT_INGRESOS_POR_USUARIO', text:'Ingresos por usuario'},
    {key:'TXT_INGRESOS_POR_FORMA_DE_PAGO', text:'Ingresos por forma de pago'},
    {key:'TXT_ANALISIS_DE', text:'Análisis de'},
    {key:'TXT_ANALISIS_HISTORICO', text:'Análisis Histórico'},

    //productos
    {key:'TXT_VARIEDADES_POR_CATEGORIA', text:'Variedades por categoría'},
    {key:'TXT_STOCK_POR_CATEGORIA', text:'Stock por categoría'},
    {key:'TXT_VALOR_STOCK_COMPRA', text:'Valor stock compra'},
    {key:'TXT_VALOR_STOCK_VENTA', text:'Valor stock venta'},
    {key:'TXT_PRECIOS_OBSOLETOS', text:'Precios obsoletos'},
    {key:'TXT_ACTUALIZADO', text:'Actualizado'},
    {key:'TXT_VER_TODOS', text:'Ver todos'},
    {key:'TXT_ESTADO_ACTUAL', text:'Estado actual'},

    //meses
    {key:'TXT_MES_1', text:'Enero'},
    {key:'TXT_MES_2', text:'Febrero'},
    {key:'TXT_MES_3', text:'Marzo'},
    {key:'TXT_MES_4', text:'Abril'},
    {key:'TXT_MES_5', text:'Mayo'},
    {key:'TXT_MES_6', text:'Junio'},
    {key:'TXT_MES_7', text:'Julio'},
    {key:'TXT_MES_8', text:'Agosto'},
    {key:'TXT_MES_9', text:'Septiembre'},
    {key:'TXT_MES_10', text:'Octubre'},
    {key:'TXT_MES_11', text:'Noviembre'},
    {key:'TXT_MES_12', text:'Diciembre'},

    //relativos
    {key:'TXT_ANIO', text: 'año'},
    {key:'TXT_ANIOS', text: 'años'},
    {key:'TXT_DIA', text: 'día'},
    {key:'TXT_DIAS', text: 'días'},
    {key:'TXT_EN', text: 'en'},
    {key:'TXT_HACE', text: 'hace'},
    {key:'TXT_HORAS', text: 'horas'},
    {key:'TXT_MES', text: 'mes'},
    {key:'TXT_MESES', text: 'meses'},

    {'key':'CFG_AFIP_PASO_1', 'text':'Te guiaremos en la integración con los servicios de AFIP'},
    {'key':'CFG_AFIP_PASO_2', 'text':'Acceder con Clave Fiscal en la página de la AFIP'},
    {'key':'CFG_AFIP_PASO_3', 'text':'Ir a "Mis servicios", buscar Administración de Certificados Digitales y hacerle clic'},
    {'key':'CFG_AFIP_PASO_4', 'text':'De ser necesario, seleccionar a quien representar. Presionar el botón "Agregar Alias"'},
    {'key':'CFG_AFIP_PASO_5', 'text':'Descargar archivo CSR y guardarlo en un lugar de fácil acceso'},
    {'key':'CFG_AFIP_PASO_6', 'text':'En el campo "Alias", ingresar un nombre sencillo (ej.: ECO) con el cual identificar este sistema. ANOTAR, ya que luego habrá que indicarlo. Presionar en "Seleccionar archivo", y elegir el archivo descargado en el paso anterior. Presionar "Agregar alias"'},
    {'key':'CFG_AFIP_PASO_7', 'text':'Presionar "Ver" sobre el alias agregado, y en la nueva pantalla descargar el archivo CRT'},
    {'key':'CFG_AFIP_PASO_8a', 'text':'Completar el Alias ingresado en AFIP'},
    {'key':'CFG_AFIP_PASO_8b', 'text':'Buscar archivo CRT descargado en paso anterior'},
    {'key':'CFG_AFIP_PASO_9', 'text':'Volver a pantalla de "Mis servicios", buscar y seleccionar el servicio "Administrador de Relaciones de Clave Fiscal"'},
    {'key':'CFG_AFIP_PASO_10', 'text':'Presionar en "Nueva Relación"'},
    {'key':'CFG_AFIP_PASO_11', 'text':'Presionar en AFIP -> WebServices -> Facturación Electrónica'},
    {'key':'CFG_AFIP_PASO_12', 'text':'En la fila representante, presionar "Buscar"'},
    {'key':'CFG_AFIP_PASO_13', 'text':'En la fila "Computador Fiscal" seleccionar el Alias. Presionar en "Confirmar". En la nueva pantalla volver a confirmar'},
    {'key':'CFG_AFIP_PASO_14', 'text':'Luego de finalizar: recordar pasar por nuestra pantalla de Configuración. Los puntos de venta cargados deben coincidir con los declarados ante AFIP, caso contrario no se enviarán los movimientos al organismo'},
    
    {'key':'MSJ_ORDEN_CON_REMITO', 'text':'La órden de compra ya posee remito'},
    {'key':'MSJ_INGRESE_DETALLES', 'text':'Debe ingresar al menos un detalle'},
    {'key':'MSJ_SELECCIONE_DETALLES', 'text':'Debe seleccionar al menos un detalle'},
    {'key':'MSJ_SUPERA_LIMITE_CTA_CTE', 'text':'El cliente supera el límite de Cta. Cte.'},
    {'key':'MSJ_PEDIDO_RECIBIDO', 'text':'El pedido está recibido'},
    {'key':'MSJ_PEDIDO_ANULADO', 'text':'El pedido está anulado'},
    {'key':'MSJ_DADO_DE_BAJA', 'text':'Elemento borrado'},
    {'key':'MSJ_SIN_STOCK', 'text':'Sin stock'},
    {'key':'MSJ_OPERACION_EXITOSA', 'text':'Operación exitosa'},
    {'key':'MSJ_OPERACION_FALLIDA', 'text':'Operación fallida'},
    {key:'MSJ_OPERACION_PARCIAL', text:'Operación ejecutada parcialmente'},
    {'key':'MSJ_NO_HAY_REGISTROS', 'text':'No hay registros para mostrar'},
    {'key':'MSJ_SEGURO_BORRAR', 'text':'¿Seguro de borrar la selección?'},
    {'key':'MSJ_SEGURO_REACTIVAR', 'text':'¿Seguro de reactivar la selección?'},
    {'key':'MSJ_PREG_SEGURO_CONTINUAR', 'text':'¿Seguro de continuar?'},
    {'key':'MSJ_APLICADO_DESCUENTO_EFECTIVO', 'text':'Aplicado descuento por cobro en Efectivo'},
    {key:'MSJ_REGISTROS_ACTUALIZADOS', text:'Registros actualizados'},
    
    {'key':'ADV_CONFIGURAR_MOTIVOS_TIPOS_COBRO', 'text':'No hay motivos de ajuste relacionados a formas de cobro'},
    {'key':'ADV_MESAS_NO_CONFIGURADAS', 'text':'No se han configurado mesas, dirijirse a la pantalla de Configuraciones e ingresar la cantidad que posee'},
    {'key':'ADV_SIN_CAJA_ACTIVA', 'text':'Para poder operar hay que tener una caja abierta, dirigirse al menú "Cajas"'},

    {'key':'ERR_ARCHIVO_ELEGIDO_NO_VALIDO', 'text':'El archivo elegido no es válido'},
    {'key':'ERR_USUARIO_INEXISTENTE', 'text':'Usuario no encontrado'},
    {'key':'ERR_CREDENCIALES_INCORRECTAS', 'text':'Credenciales incorrectas'},
    {'key':'ERR_SIN_ROLES_ASIGNADOS', 'text':'Usuario sin roles asignados'},
    {'key':'ERR_PRODUCTO_INEXISTENTE', 'text':'El producto no existe'},
    {'key':'ERR_STOCK_INSUFICIENTE', 'text':'No hay stock suficiente'},
    {'key':'ERR_FALTA_CLIENTE', 'text':'Falta relacionar a un cliente'},
    {'key':'ERR_CANTIDAD_MENOR_IGUAL_CERO', 'text':'La cantidad ingresada debe ser mayor a cero'},
    {'key':'ERR_INGRESE_PRODUCTOS', 'text':'No se seleccionaron productos'},
    {'key':'ERR_INGRESE_PAGOS', 'text':'No se definieron pagos'},
    {'key':'ERR_RAZON_SOCIAL_UK', 'text':'La razón social ya existe'},
    {'key':'ERR_PRODUCTO_CODIGO_UK', 'text':'El código del producto ya existe'},
    {'key':'ERR_PRODUCTO_DESCRIPCION_UK', 'text':'El nombre del producto ya existe'},
    {'key':'ERR_MONTO_RECIBIDO_MENOR', 'text':'El importe cobrado no alcanza al total de la venta'},
    {'key':'ERR_PROVEEDOR_PRODUCTO_UK', 'text':'El proveedor ya tiene registrado el producto'},
    {'key':'ERR_CAJA_INEXISTENTE', 'text':'La caja indicada no existe'},
    {'key':'ERR_CIERRE_CAJA_CERRADA', 'text':'La caja a cerrar ya se encuentra cerrada'},
    {'key':'ERR_FALTA_SALDO', 'text':'El saldo debe ser mayor que cero'},
    {'key':'ERR_PUNTO_VENTA_OPERANDO', 'text':'El punto de venta ya se encuentra abierto'},
    {'key':'ERR_PUNTO_VENTA_CERRADO', 'text':'El punto de venta ya se encuentra cerrado'},
    {'key':'ERR_CAJA_NO_ES_ULTIMA', 'text':'Solo puede reabrir la última caja'},
    {'key':'ERR_CAJA_CERRADA', 'text':'No se pueden registrar ventas en una caja cerrada'},
    {'key':'ERR_NO_ES_EL_CAJERO', 'text':'Intentas operar una caja que no abriste'},
    {'key':'ERR_FALTA_PUNTO_VENTA', 'text':'Faltó seleccionar el punto de venta'},
    {'key':'ERR_CATEGORIAS_PRODUCTOS_NOMBRE_UK', 'text':'El nombre de la categoría ya existe'},
    {'key':'ERR_NOMBRE_TIPOS_COBRO_UK', 'text':'La forma de cobro ya existe'},
    {'key':'ERR_INESPERADO', 'text':'Ups! algo salió mal. Informar el problema.'},
    {'key':'ERR_CLIENTE_NO_EXISTE','text':'El cliente elegido no existe'},
    {'key':'ERR_CLIENTE_SIN_DISPONIBLE','text':'El cliente no tiene saldo disponible en cta. cte.'},
    {'key':'ERR_FALTA_CLIENTE','text':'Faltó seleccionar el cliente'},
    {'key':'ERR_COBROS_NEGATIVOS','text':'Los cobros deben ser mayores que cero'},
    {key:'ERR_VALIDACIONES',text:'Los datos enviados no coinciden con los esperados'},
    {key:'ERR_TOKEN_EXPIRADO',text:'La sesión expiró. Volver a iniciar sesión'},
    {key:'ERR_NO_AUTORIZADO',text:'Acción no autorizada'},

    {key:'ERR_AFIP_SAVE_LOGIN', text:'Error al intentar guardar el certificado de afip'},
    {key:'ERR_AFIP_CUIT_CONSULTADO',text:'El CUIT consultado no es un valor válido'},
    {key:'ERR_AFIP_ns1:coe.notAuthorized', text:'Falta autorizar el servicio de AFIP. Comprobar en menú "Mi empresa", tarjeta AFIP, estados'},
    {key:'ERR_AFIP_ns1:coe.alreadyAuthenticated', text:'El CEE ya posee un TA valido para el acceso al WSN solicitado'}
];