import { UrlApi } from '../config/Services'
import { dateToJson } from '../utils/DateUtil'
import { DeleteCall, GetCall, PatchCall, PostCall, PutCall } from './_serviceHelper2'

const serviceUrl = `${UrlApi()}/cajas`

export const listarCajas = (filters) => {
    var params = {
        id: filters?.id,
        cajeroId: filters?.user?.id,
        fechaDesde: dateToJson(filters?.desde),
        fechaHasta: dateToJson(filters?.hasta),
        activo: filters?.activo,
        pageNumber: filters?.pageNumber,
        pageSize: filters?.pageSize
    }
    return GetCall(serviceUrl, params)
}

export const abrirCaja = (unaCaja) => PostCall(serviceUrl, unaCaja)
export const reabrirCaja = (unaCaja) => PatchCall(serviceUrl, unaCaja)
export const cerrarCaja = (unaCaja) => DeleteCall(serviceUrl, unaCaja)
export const listarEventosCaja = (unaCaja) => GetCall(serviceUrl + '/eventos', unaCaja) //ex listarModificaciones
export const pdfCierreCaja = (unaCaja) => GetCall(serviceUrl + '/pdfCierre', unaCaja)
export const resumenDeCaja = (unaCaja) => GetCall(serviceUrl + '/resumen', unaCaja) //ex resumenMovimientos

/******* MOVIMIENTOS *******/

export const listarMovimientos = (filters) => {
    var params = {
        cajaId: filters.caja.id,
        motivoId: filters.motivo?.id
    }
    return GetCall(serviceUrl + '/movimientos', params)
}

export const agregarMovimiento = (unMovimiento) => {
    let auxDetProd = unMovimiento.detallesProductos.map(elem => {
        return {
            producto: { id: elem.producto.id },
            cantidad: elem.cantidad,
            precio: elem.precio
        }
    })
    let auxDetCob = unMovimiento.detallesCobros
        .filter(elem => elem.importe > 0)
        .map(elem => ({
            formaDePago: { id: elem.formaDePago.id },
            importe: elem.importe
        }))
    let objMin = {
        caja: {
            id: unMovimiento.caja.id
        },
        motivoMovimiento: {
            id: unMovimiento.motivoMovimiento.id
        },
        detallesProductos: auxDetProd,
        detallesCobros: auxDetCob,
        consumoMesaId: unMovimiento.consumoMesaId,
        cliente: unMovimiento.cliente ?? undefined
    }
    return PostCall(serviceUrl + '/movimientos', objMin)
}

export const listarDetallesCobros = (unMovimiento) => GetCall(serviceUrl + '/movimientos/detallesCobros', unMovimiento)
export const listarDetallesProductos = (unMovimiento) => GetCall(serviceUrl + '/movimientos/detallesProductos', unMovimiento)

/******* MOTIVOS DE MOVIMIENTO *******/

export const listarMotivosMovimiento = (filters) => GetCall(serviceUrl + '/movimientos/motivos', filters)
export const agregarMotivoMovimiento = (unMotivo) => PostCall(serviceUrl + '/movimientos/motivos', unMotivo)
export const modificarMotivoMovimiento = (unMotivo) => PutCall(serviceUrl + '/movimientos/motivos', unMotivo)
export const borrarMotivosMovimiento = (ids) => DeleteCall(serviceUrl + '/movimientos/motivos', {ids})
export const reactivarMotivosMovimiento = (ids) => PatchCall(serviceUrl + '/movimientos/motivos', {ids})

/******* FORMAS DE PAGO *******/

export const listarFormasDePago = (filters) => GetCall(serviceUrl + '/formasDePago', filters)
export const agregarFormaDePago = (unaFormaDePago) => PostCall(serviceUrl + '/formasDePago', unaFormaDePago)
export const modificarFormaDePago = (unaFormaDePago) => PutCall(serviceUrl + '/formasDePago', unaFormaDePago)
export const borrarFormasDePago = (ids) => DeleteCall(serviceUrl + '/formasDePago', {ids})
export const reactivarFormasDePago = (ids) => PatchCall(serviceUrl + '/formasDePago', {ids})

/******* REPORTES *******/

export const reporteVentasConcretadas = (filtros) => {
    var params = {
        agrupa: filtros?.agrupa
    }
    return GetCall(serviceUrl + '/reportes/ventasConcretadas', params)
}

export const reporteProductosMasYMenosVendidos = (filtros) => {
    var params = {
        periodo: filtros?.periodo
    }
    return GetCall(serviceUrl + '/reportes/productosMasYMenosVendidos', params)
}

export const reporteIngresosPorUsuario = (filtros) => {
    var params = {
        periodo: filtros?.periodo
    }
    return GetCall(serviceUrl + '/reportes/ingresosPorUsuario', params)
}

export const reporteIngresosPorFormaDePago = (filtros) => {
    var params = {
        periodo: filtros?.periodo
    }
    return GetCall(serviceUrl + '/reportes/ingresosPorFormaDePago', params)
}